import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    overflow: 'hidden',
    marginBottom: 20,
    borderRadius: 8,
    padding: 15,
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorPrimary,
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.braindateCardTimeInactive}`,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  title: {
    margin: [0, 0, 25, 0],
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
  },
};
