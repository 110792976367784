import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import useSidebar from 'src/shared/app/base/component/template/hooks/useSidebar';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './ContentWithSidebar.style';
import Sidebar from './sidebar/SideBar';

type Props = {
  children: Node;
  className?: string;
};
const useStyles = createUseThemeStyles(styles);

const ContentWithSidebar = ({ children, className }: Props) => {
  const { hasSidebar } = useSidebar();
  const classes = useStyles({
    hasSidebar,
  });
  return (
    <div className={classNames(classes.root, className)}>
      <Sidebar />
      {children}
    </div>
  );
};

export default memo<Props>(ContentWithSidebar);
