import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 24,
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  logo: {
    maxWidth: 80,
    minHeight: 80,
    objectFit: 'contain',
  },
  name: {
    marginTop: 9,
    fontSize: rem(14, baseFontSize),
  },
};
