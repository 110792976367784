import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import DocumentTitle from 'src/shared/app/base/component/document/DocumentTitle';
import ContentWithSidebar from 'src/shared/app/base/component/template/component/ContentWithSidebar';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/not-found/l10n/notFoundL10n';

import styles from './NotFoundMain.style';

const useStyles = createUseThemeStyles(styles);

const NotFoundMain = (): Node => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <ContentWithSidebar>
      <DocumentTitle>{intl.formatMessage(messages.title)}</DocumentTitle>
      <div className={classes.root}>
        <p className={classes.title}>
          {intl.formatMessage(messages.statement)}
        </p>
        <p>{intl.formatMessage(messages.details)}</p>
      </div>
    </ContentWithSidebar>
  );
};

export default memo(NotFoundMain);
