import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {},
  title: {
    fontSize: rem(18, baseFontSize),
  },
  content: {
    fontSize: rem(14, baseFontSize),
    marginBottom: 16,
    '& > p': {
      marginTop: 16,
    },
  },
};
