import { memo } from 'react';

import { useIntl } from 'react-intl';

import type { BraindateEventSponsor } from '@braindate/domain/lib/event/type';
import {
  getEventPlugins,
  getEventSponsorLink,
  getEventSponsorLogo,
  getEventSponsorLogoAltText,
  getEventSponsorName,
  getEventSponsorPluginMainNavTitle,
} from '@braindate/domain/lib/event/util';

import SidebarCard from 'src/shared/app/base/component/template/component/sidebar/SidebarCard';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { sponsorshipPlugin as sponsorshipPluginKey } from 'src/shared/app/feature/settings/featureSettings';
import messages from 'src/shared/app/nav/l10n/navigationL10n.json';
import useEvent from 'src/shared/domain/event/hook/useEvent';

import SponsorItem from './SponsorItem';
import styles from './SponsorsCards.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  eventSponsors: BraindateEventSponsor[];
};

const SponsorsCards = ({ eventSponsors }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const event = useEvent();
  const sponsorshipPlugin = getEventPlugins(event)[sponsorshipPluginKey];
  if (!sponsorshipPlugin) return null;
  const sponsorsHeaderTitle = getEventSponsorPluginMainNavTitle(
    sponsorshipPlugin,
    intl.locale,
  );
  const sponsors = (eventSponsors || []).map((sponsor) => ({
    name: getEventSponsorName(sponsor, intl.locale),
    logo: getEventSponsorLogo(sponsor),
    logoAltText: getEventSponsorLogoAltText(sponsor, intl.locale),
    url: getEventSponsorLink(sponsor),
  }));
  if (!sponsors.length) return null;
  return (
    <SidebarCard
      title={
        sponsorsHeaderTitle || intl.formatMessage(messages.meetOurSponsors)
      }
    >
      <div className={classes.sponsorsList}>
        {sponsors.map((sponsor, key) => (
          <SponsorItem sponsor={sponsor} key={`sponsor-${key}`} />
        ))}
      </div>
    </SidebarCard>
  );
};

export default memo<Props>(SponsorsCards);
